body {
    background: white !important;
}

label {
	font-weight: normal !important;
}

.ui.dropdown.selection.multiple {
    width: 100%;
}

.media-thumbnail .img-responsive {
    min-height: 128px;
}

.navbar {
  min-height: 77px;
}


div#app-navbar-collapse {
    padding-top: 15px;
    padding-bottom:10px;
}

.field.error {
    color: #a94442;
}

div#avva-logo {
    margin-bottom: 25px;
}

.navbar-brand {
	padding: 0px 35px !important;
}

div#user-msg {
    padding-bottom: 10px;
    margin-bottom: 0px;
}

.thumbnail .img-responsive {
    max-height: 190px;
    min-height: 190px;
}

.navbar-brand {
    margin-top: 11px;
}

img.img-thumbnail.edit-thumbnail {
    width: 250px;
}

.img-responsive {
    max-height: calc(80vh - 200px);
    margin: 0 auto;
}

img.slide-image {
    max-height: 500px !important; 
    margin: 0 auto;
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    left: 0;
    right: 0;
}

.carousel-caption {
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    background: #afafaf78;
    border: 1px solid;
    border-radius: 5px;
    bottom: 5px !important;
}

.slide .item {
     height: 500px !important;
}

.blurred-bg-main {
    background-image: url("/storage/images/gJgG3lbmI1WBc1Sz6uaU32fZgzb0MhLeOFvAp2qJ.jpeg");
    background-size: cover;
}

.blurred-bg {
    background-image: url("/storage/images/txX1GBQuu1UfeJYfHB1AQjQlZWD6YgWT7Iy9wHJy.jpeg");
    background-size: 100%;
    background-size: cover;
}

.carousel-inner .item img {
    width: auto !important;
}



/**
 * RESET
 * Reference: http://meyerweb.com/eric/tools/css/reset/
 */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}


ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
}

code, pre, samp {
  font-family: monospace;
}

/**
 * BASIC TYPE
 */

img, video {
  max-width: 100%;
  vertical-align: middle;
}

p:not(:last-child) {
  margin: 0 0 1em;
}

h1, h2, h3, h4 {
  margin: .5em 0 .75em;
  line-height: 1.3;
}

h1 {
  font-size: 1.5em;
}

h2 {
  font-size: 1.25em;
}

h3 {
  font-size: 1.125em;
}

h4 {
  font-size: 1em;
}

/**
 * BOX HACKING
 */

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
.wrapper { 
  max-width: 1280px;
  margin-right: auto;
  margin-left: auto;
  padding: 1.5em;
}
body {
  background-color: #cccccc;
  color: #333333;
}
.masonry-wrapper {
  padding: 1.5em;
  max-width: 960px;
  margin-right: auto;
  margin-left: auto;
}
.masonry {
  columns: 1;
  column-gap: 10px;
}
.masonry-item {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 10px;
}
.masonry-item p {
    color: #034ea2;
}


.masonry-item, .masonry-content {
  border-radius: 4px;
  overflow: hidden;
}
.masonry-item {
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, .3));
  transition: filter .25s ease-in-out;
}
.masonry-item:hover {
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, .3));
}
.masonry-footer {
  font-size: .75em;
  opacity: .25;
  text-align: center;
  padding-top: 3em; 
  padding-bottom: 3em;
  margin-bottom: -1.5em;
  transition: opacity 1s ease-in-out;
}
.masonry-footer a {
  color: currentColor;
}
.masonry-footer:hover, .masonry-footer:active, .masonry-footer:focus {
  opacity: .75;
}

.super-search.hide.active {
    height: 120px;
}


.quick-search .form-control {
    color: #034ea2 !important;
}



.page-copyright ul li {
    display: inline-block;
    line-height: 35px;
    padding-right: 20px;
}


/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden; -webkit-transform: translate3d(0,0,0);}
#cboxWrapper {max-width:none;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; padding:0; margin:0;}
#colorbox, #cboxContent, #cboxLoadedContent{box-sizing:content-box; -moz-box-sizing:content-box; -webkit-box-sizing:content-box;}

/* 
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
div#cboxTitle {color: #034ea2;}
#cboxOverlay{background:#fff;}
#colorbox{outline:0;}

#cboxContent{background:#fff; overflow:hidden;}
.cboxIframe{background:#fff;}
#cboxError{padding:50px; border:1px solid #ccc;}
#cboxLoadedContent{margin-bottom:20px;}
#cboxTitle{position:absolute; bottom:0px; left:0; text-align:center; width:100%; color:#999;}
#cboxCurrent{position:absolute; bottom:0px; left:100px; color:#999;}


/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}

#cboxSlideshow{position:absolute; bottom:0px; right:42px; color:#444;}
#cboxPrevious{position:absolute; bottom:0px; left:0; color:#444;}
#cboxNext{position:absolute; bottom:0px; left:63px; color:#444;}
#cboxClose{position:absolute; bottom:0; right:0; display:block; color:#444;}

/*
  The following fixes a problem where IE7 and IE8 replace a PNG's alpha transparency with a black fill
  when an alpha filter (opacity change) is set on the element or ancestor element.  This style is not applied to or needed in IE9.
  See: http://jacklmoore.com/notes/ie-transparency-problems/
*/
.cboxIE #cboxTopLeft,
.cboxIE #cboxTopCenter,
.cboxIE #cboxTopRight,
.cboxIE #cboxBottomLeft,
.cboxIE #cboxBottomCenter,
.cboxIE #cboxBottomRight,
.cboxIE #cboxMiddleLeft,
.cboxIE #cboxMiddleRight {
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
}

#cboxLoadedContent {overflow: hidden !important;}

.scroll{height:100%;}
.jscroll-inner {
  overflow: hidden;
}

.carousel-inner h3 {
    color: white;
}


#pagination {
    position: -webkit-fixed;
    position: fixed;
    bottom: 0px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    left: 0%;
}

.modal-title {
    padding-left: 10%;
}

.modal-body img {
    border-radius: 4px;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
}

.box {border: 0 !important;}

@media only screen and (max-width: 1023px) and (min-width: 768px) {  .masonry {
    columns: 2;
  }
}
@media only screen and (min-width: 1024px) {
  .masonry {
    columns: 6;
  }
}

/* Mobile */

@media only screen and (max-width: 480px) {
    .carousel-inner .item {
        max-height: 230px !important;
    }

    h1 {
        font-size: 17px;
    }

    .trademark-menu {
        display: inline !important;
    }
    .nav.navbar-nav {
        padding-left: 20px;
    }

    .quick-search > .form-control {
        font-size: 23px !important;
        height: 70px !important;
    }
    .super-search.active .quick-search {
        height: 40px;
    }
    #cboxClose {left: 0 !important; }
}
